<template>
  <div class="CardPagamentoContainer">
    <v-row>
      <pagamento-lista-upload
        class="ma-3"
        :disabled="!(selecionados && selecionados.length > 0)"
        :pagamentos="selecionados">
      </pagamento-lista-upload>
      <geracao-aap-arquivo
        class="ma-3"
        v-if="canAccessPageList"
        :houve-selecao="houveSelecao"
        :pagamentos="pagamentosParaGeracao"
        @GeracaoAaapArquivo_sucesso="filtrar">
      </geracao-aap-arquivo>
      <aprovacao-massiva-pagamento
        class="ma-3"
        v-if="canAccessAprovMass"
        :disabled="!(selecionados && selecionados.length > 0)"
        :pagamentos="selecionados"
        @AprovacaoMassiva_sucesso="filtrar"
        @AprovacaoMassiva_limpaSelecionados="limpaSelecionados">
      </aprovacao-massiva-pagamento>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="lista"
      v-model="selecionados"
      item-key="id"
      show-select
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [12, 24, 36, 48, 60, 72, 84],
      }">
      <template v-slot:item.mais_opcoes="{ item }">
        <v-menu bottom
          origin="center center"
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :id="'more_vert_' + item.id"
              @click="carregarVariaveis(item)"
              icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list v-if="status !== ''">
            <v-list-item @click="selectItem(item)">
              <v-list-item-action>
                 <v-icon>visibility</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.visualizar_pagamento') }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="mostrarAcompanhamento" @click="abrirAcompanhamento">
              <v-list-item-action>
                <v-icon>assignment_turned_in</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.acompanhamento') }}</v-list-item-title>
            </v-list-item>

            <div v-if="apresentaJustificativaAoSolicitar">
              <acao-fluxo
                  v-if="podeSolicitarAprovacao()"
                  ref="acaoSolicitarAprovacao"
                  icone="send"
                  :texto-acao="$tc('label.solicitar_aprovacao')"
                  :status="item.status"
                  :layout-grid="true"
                  @AcaoFluxo_executarAcao="iniciaFluxo">
              </acao-fluxo>
            </div>
            <div v-else>
              <popover-confirmacao
                v-if="podeSolicitarAprovacao()"
                :message="$tc('message.deseja_solicitar_aprovacao_pagamento', 1)"
                @agree="iniciaFluxo"
                icone="send"
                :layout-grid="true"
                :textoAcao="$t('label.solicitar_aprovacao')">
              </popover-confirmacao>
            </div>

            <acao-fluxo
                v-if="podeCancelarPagamento()"
                ref="acaoCancelar"
                icone="cancel"
                :mostrarRodape="true"
                :validarJustificativa="true"
                :layout-grid="true"
                @AcaoFluxo_executarAcao="cancelarPagamento"
                :texto-acao="isStatusPendente ? $tc('label.cancelar', 1) : isStatusVencido ? $tc('label.recuperar_pagamento') :  `${$tc('label.cancelar', 1)} ${$tc('title.fluxo_aprovacao')}`">
            </acao-fluxo>

            <acao-fluxo
                v-if="exibeAprovar"
                ref="acaoAprovar"
                icone="thumb_up"
                :id-acao="item.idAcao"
                :texto-acao="$t('label.aprovar')"
                :acao="item"
                :status="item.status"
                :layout-grid="true"
                :configuracao="carregarConfiguracao(item.idAcao)"
                @AcaoFluxo_executarAcao="aprovar">
            </acao-fluxo>

            <acao-fluxo
              v-if="exibeAprovar"
              ref="acaoEmAnalise"
              icone="announcement"
              :texto-acao="$t('label.solicitar_analise')"
              :status="item.status"
              :layout-grid="true"
              :validar-justificativa="true"
              @AcaoFluxo_executarAcao="enviarAnalise">
            </acao-fluxo>

            <acao-fluxo
                ref="acaoReprovar"
                icone="thumb_down"
                :texto-acao="$t('label.reprovar')"
                :status="item.status"
                :layout-grid="true"
                :validar-justificativa="true"
                v-if="exibeReprovar"
                @AcaoFluxo_executarAcao="reprovar">
            </acao-fluxo>

          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.aap="{ item }">
        <a @click="downloadAap(item.arquivoAap)">{{ item.aap }}</a>
      </template>
      <template v-slot:item.id_acao_trade_previa="{ item }">
        {{ item.idAcaoTradePrevia }}
      </template>
      <template v-slot:item.id_acao="{ item }">
        {{ item.idAcao }}
      </template>
      <template v-slot:item.tipo_pagamento="{ item }">
        {{ $tc(`tipos_pagamentos.${item.tipoPagamento.toUpperCase()}`, 1) }}
      </template>
      <template v-slot:item.tipo_acao="{ item }">
        {{ item.tipoAcao }}
      </template>
      <template v-slot:item.tipo_verba="{ item }">
        {{ item.tipoVerba }}
      </template>
      <template v-slot:item.valor="{ item }">
        {{ getMoney(item.valor) }}
      </template>
      <template v-slot:item.foco_acao="{ item }">
        {{ item.focoAcao }}
      </template>
      <template v-slot:item.cliente_recebedor="{ item }">
        {{ item.clienteRecebedor }}
      </template>
      <template v-slot:item.status="{ item }">
        <status :status="item.status" :tooltip="item.status"></status>
      </template>
    </v-data-table>

    <acompanhamento
        ref="modalAcompanhamento"
        :fluxo="statusPassos"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Status from '@/produto/shared-components/Status';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import { listarPagamentosSuzano } from '@/common/resources/pagamento-suzano';
import { getMoney } from '@/produto/common/functions/helpers';
import { listarPagamentosNivel2Simplificado } from '@/produto/common/resources/liquidacao/pagamento';
import PagamentoListaUpload from '@/spa/liquidacao-acao/PagamentoListaUpload';
import AprovacaoMassivaPagamento from '@/spa/liquidacao-acao/AprovacaoMassivaPagamento';
import GeracaoAapArquivo from '@/spa/liquidacao-acao/GeracaoAapArquivo';
import basePath from '@/produto/common/functions/api-resource';
import exportacao from '@/produto/common/resources/downloads';
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import PopoverConfirmacao from '@/produto/shared-components/PopoverConfirmacao';
import Acompanhamento from '@/produto/shared-components/workflow/Acompanhamento';

export default {
  name: 'LiquidacaoEmLista',
  components: {
    PagamentoListaUpload,
    GeracaoAapArquivo,
    AprovacaoMassivaPagamento,
    Status,
    AcaoFluxo,
    PopoverConfirmacao,
    Acompanhamento,
  },
  data() {
    return {
      pagamentoResources: this.$api.pagamentoSuzano(this.$resource),
      pagamentoResource: this.$api.pagamento(this.$resource),
      workflowPagamentoAcaoResource: this.$api.workflowPagamentoAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      lista: [],
      selecionados: [],
      filtrosAplicados: {},
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      idPagamento: 0,
      idAcaoPrevia: 0,
      config: null,
      exibirAcompanhamento: {
        type: Boolean,
        default: true,
      },
      exibirSolicitarAprovacao: {
        type: Boolean,
        default: true,
      },
      perguntarProximo: {
        type: Boolean,
        default: false,
      },
      indJustificativaAnalise: {
        type: Boolean,
        default: false,
      },
      indHabilitaFluxo: {
        type: Boolean,
        default: false,
      },
      dados: [],
      acao: {
        type: Object,
        default: () => ({}),
      },
      aprovadorFluxo: false,
      podeCancelar: Boolean,
      podeReprovar: Boolean,
      statusPassos: {},
      status: '',
      headers: [
        {
          align: 'left', value: 'mais_opcoes', width: '5%',
        },
        {
          text: this.$tc('label.aap', 1), align: 'left', value: 'aap', sortable: true, width: '5%',
        },
        {
          text: 'Cod. Planejamento Massivo', align: 'left', value: 'id_acao_trade_previa', sortable: true, width: '10%',
        },
        {
          text: 'Cod. Ação Tática', align: 'left', value: 'id_acao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.forma_pagamento', 1), align: 'left', value: 'tipo_pagamento', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.tipo_acao', 1), align: 'left', value: 'tipo_acao', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.tipo_de_verba', 1), align: 'left', value: 'tipo_verba', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.valor', 1), align: 'left', value: 'valor', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.foco_acao', 1), align: 'left', value: 'foco_acao', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.cliente_recebedor', 1), align: 'left', value: 'cliente_recebedor', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.status', 1), align: 'left', value: 'status', sortable: true, width: '10%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroLiquidacaoAcaoNivel2',
      'filtroLiquidacaoAcao',
    ]),
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
    canAccessPageList() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'GERAR_AAP').length;
    },
    canAccessAprovMass() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'APROV_PAG_MASSIVA').length;
    },
    exibeAprovar() {
      return this.getStatusPagamento() === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    exibeReprovar() {
      return this.exibeAprovar && this.getPodeReprovar();
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && !this.isStatusPendente && !this.isStatusVencido
          && !this.isStatusCancelado;
    },
    isStatusAguardandoAprovacao() {
      return this.getStatusPagamento() === 'AGUARDANDO_APROVACAO';
    },
    isStatusPendente() {
      return this.getStatusPagamento() === 'PENDENTE';
    },
    isStatusVencido() {
      return this.getStatusPagamento() === 'VENCIDO';
    },
    isStatusEmAnalise() {
      return this.getStatusPagamento() === 'EM_ANALISE';
    },
    isStatusCancelado() {
      return this.getStatusPagamento() === 'CANCELADO';
    },
    apresentaJustificativaAoSolicitar() {
      return this.indHabilitaFluxo && this.indJustificativaAnalise
          && this.isStatusEmAnalise;
    },
    mostrarJustificativa() {
      return this.indHabilitaFluxo && this.indJustificativaAnalise
          && this.isStatusAguardandoAprovacao && this.observacaoAcao;
    },
    pagamentosParaGeracao() {
      return this.selecionados.length > 0 ? this.selecionados : this.lista;
    },
    houveSelecao() {
      return this.selecionados.length > 0;
    },
  },
  watch: {
    filtroLiquidacaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoAcao;
        this.filtrar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoAcao) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel2',
    ]),
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroLiquidacaoAcaoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoAcaoNivel2.quantidatePorPagina;
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    indHabilitarEvidencia() {
      if (!this.config || !this.config.passo6
        || !this.config.passo6.liquidacao) {
        return false;
      }
      return this.config.passo6.liquidacao.indHabilitarEvidencia;
    },
    indEvidenciaManualObrigatorio() {
      return this.indHabilitarEvidencia && this.config.passo6.liquidacao.indEvidencia;
    },
    evidenciaObrigatoriaEnviada() {
      return this.acao.temEvidencia && this.indEvidenciaManualObrigatorio;
    },
    iniciaFluxo(observacao = null) {
      const idPagamento = this.getIdPagamento();
      this.solicitaAprovacao(idPagamento, observacao);
    },
    carregarDadosPagamento(idPagamento) {
      return this.pagamentoResource.buscarPagamento({ idPagamento })
        .then((res) => {
          this.acao = { ...res.data };
        }).then(() => {
          Promise.all([
            this.carregarAcao(this.acao.idAcao),
          ]);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarAcao(idAcao) {
      return this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          const { passoGeral, passoComportamento } = res.data;
          this.acao = {
            ...this.acao,
            ...passoGeral.mapaExtensoes,
            ...passoGeral.mapaCamposDinamicos,
            valor: this.acao.valor != null ? this.acao.valor : passoGeral.valor,
          };
          if (passoComportamento) {
            this.acao = {
              ...this.acao,
              ...passoComportamento.mapaExcecoes,
            };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarVariaveis(objPagamento) {
      this.idPagamento = objPagamento.id;
      this.status = objPagamento.status;
      const { idAcao } = objPagamento;

      this.carregarConfiguracao(idAcao).then(() => {
        this.carregarDadosPagamento(objPagamento.id).then(() => {
          this.indHabilitaFluxo = this.acao.indHabilitaFluxo;
          this.exibirSolicitarAprovacao = this.evidenciaObrigatoriaEnviada();

          this.carregaInformacoesFluxo();
          this.podeSolicitarAprovacao();
          this.podeCancelarPagamento();
        });
      });
    },
    podeSolicitarAprovacao() {
      const result = (this.canEdit
          && (this.getStatusPagamento() === 'PENDENTE' || this.getStatusPagamento() === 'EM_ANALISE')
          && this.solicitanteFluxo && this.exibirSolicitarAprovacao && this.indHabilitaFluxo && this.acao.suzanoIndAapCriado);

      return result;
    },
    podeCancelarPagamento() {
      return this.canEdit
          && this.solicitanteFluxo
          && (this.getStatusPagamento() !== 'CANCELADO')
          && (this.getStatusPagamento() !== 'EM_ANALISE')
          && this.getPodeCancelar();
    },
    selectItem(item) {
      if (item.idAcaoTradePrevia) {
        this.idAcaoPrevia = item.idAcaoTradePrevia;
      } else {
        this.idAcaoPrevia = 0;
      }

      this.consultar(listarPagamentosNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;

        const params = {
          id: item.id,
          idAcao: item.idAcao,
          objetoAcao: item,
          liquidacoes: listaSimplificada,
        };

        params.status = item.status;

        if (item.idAcaoTradePrevia) {
          params.idAcaoPrevia = item.idAcaoTradePrevia;
          this.$router.push({ name: 'detalharPagamentoStatusMassivo', params });
        } else {
          this.$router.push({ name: 'detalharPagamentoStatus', params });
        }
      });
    },
    limpaSelecionados() {
      this.selecionados = [];
    },
    limparLista() {
      this.lista = [];
      this.$forceUpdate();
    },
    buscar() {
      this.consultar(listarPagamentosSuzano, (response) => {
        this.lista = this.verificarUsuarioAprovador(response.data.resposta);
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.preencherFiltrosNivel1Vuex();
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      if (this.idAcaoPrevia > 0) {
        params.cod_acao_massiva = this.idAcaoPrevia;
      }

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoAcaoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    downloadAap(String) {
      const getBasePath = basePath('job', 'suzano/pagamento/listagem');
      const resource = exportacao(this.$http);
      const parametros = `?arquivo=${String}`;
      resource.downloadGet(getBasePath, {
        param: parametros,
        url: 'download-aap',
      })
        .then(() => {
          this.$toast(this.$t('message.aap_download_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.aap_gerada_erro'));
        });
    },
    verificarUsuarioAprovador(Pagamentos) {
      Pagamentos.forEach((c) => {
        c.isSelectable = true;
      });
      return Pagamentos;
    },
    verificarUsuarioAprovadorPagamento(idPagamento) {
      if (this.isStatusAguardandoAprovacao) {
        this.workflowPagamentoAcaoResource.aprovador({ idPagamento })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
            this.$forceUpdate();
          });
      }
    },
    preencheInformacoesJustificativa() {
      if (!this.observacaoAcao) {
        return;
      }
      this.$refs.acaoMostrarJustificativa
        .preencheJustificativa(this.observacaoAcao);
    },
    carregaInformacoesFluxo() {
      if (this.getIdPagamento() && this.getStatusPagamento()) {
        this.verificarUsuarioSolicitante(this.getIdPagamento());
        this.verificarUsuarioAprovadorPagamento(this.getIdPagamento());
      }
    },
    verificarUsuarioSolicitante(idPagamento) {
      if (this.isStatusPendente
          || this.isStatusVencido
          || this.isStatusEmAnalise
          || this.isStatusAguardandoAprovacao) {
        this.workflowPagamentoAcaoResource.solicitante({ idPagamento })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    solicitaAprovacao(idPagamento, observacao = '') {
      this.workflowPagamentoAcaoResource.iniciaFluxo({ idPagamento }, { idPagamento, observacao })
        .then(() => {
          this.$toast(this.$t('message.solicitacao_aprovacao'));
          this.limparLista();
          this.buscar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa, irProximo) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.reprovar({ idPagamento },
        { observacao: justificativa })
        .then(() => {
          this.limparLista();
          this.buscar();
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.pagamento_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.aprovarPasso({ idPagamento },
        { observacao: justificativa })
        .then(() => this.passoAprovado(idPagamento))
        .catch((err) => {
          this.$error(this, err);
        });
    },
    passoAprovado(idPagamento) {
      this.workflowPagamentoAcaoResource.status({ idPagamento })
        .then((res) => {
          if (res.data.statusFluxo === 'APROVADO') {
            this.limparLista();
            this.buscar();
            this.$toast(this.$t('message.pagamento_aprovada'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa, irProximo) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.enviarAnalise({ idPagamento },
        { observacao: justificativa })
        .then(() => {
          this.limparLista();
          this.buscar();
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.pagamento_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    cancelarPagamento(observacao) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.cancelar({ idPagamento }, { observacao })
        .then(() => {
          this.limparLista();
          this.buscar();

          if (this.isStatusPendente) {
            this.$toast(this.$t('message.pagamento_fluxo_cancelado'));
          }

          if (this.isStatusVencido) {
            this.$toast(this.$t('message.pagamento_fluxo_recuperado'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    irProximaPagina(irProximo) {
      if (irProximo) {
        this.$emit('PagamentoAcaoFluxo_irProximo');
      }
    },
    abrirAcompanhamento() {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource
        .existeFluxoCadastrado({ idPagamento })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.pagamento_finalizada_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idPagamento);
        });
    },
    exibeModalAcompanhamento(idPagamento) {
      this.workflowPagamentoAcaoResource.statusPasso({ idPagamento }, { idPagamento })
        .then((res) => {
          if (res.data.passos
                && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    getTiposVerbas() {
      return this.tiposVerbas ? this.tiposVerbas : this.acao.tiposVerbas;
    },
    getIdPagamento() {
      return this.idPagamento ? this.idPagamento : this.acao.idPagamento;
    },
    getIdAcao() {
      return this.idAcao ? this.idAcao : this.acao.idAcao;
    },
    getStatusPagamento() {
      return this.status ? this.status : this.acao.statusPagamento;
    },
    getPodeCancelar() {
      if (this.podeCancelar) {
        return this.podeCancelar;
      }
      if (this.acao && this.acao.podeCancelar) {
        return this.acao.podeCancelar;
      }
      return this.isStatusCancelado;
    },
    getPodeReprovar() {
      if (this.podeReprovar) {
        return this.podeReprovar;
      }
      if (this.acao && this.acao.podeReprovar) {
        return this.acao.podeReprovar;
      }
      return false;
    },
    carregarConfiguracao(idAcao) {
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao }).then((res) => {
        this.config = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.filtroLiquidacaoAcaoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroLiquidacaoAcao) {
      this.filtrosAplicados = this.filtroLiquidacaoAcao;
      this.filtrar();
    }
  },
};
</script>
